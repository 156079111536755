<template>
  <div>
    <h1 class="text-h4 my-6">
      Benvenuto,
      <span class="font-weight-medium">
        {{ currentUser.user.first_name }}
        {{ currentUser.user.last_name }}
      </span>
    </h1>
    <p>
      Il tuo ultimo accesso risale al
      {{ currentUser.user.prev_last_login | momentOr('DD MMMM YYYY') }} alle ore
      {{ currentUser.user.prev_last_login | momentOr('HH:mm') }} dall'indirizzo
      IP <code>{{ currentUser.user.prev_last_ip_address }}</code>.
      Non eri tu?
      <v-btn text link small :to="{ name: 'profile' }" color="primary">
        Cambia la tua password
      </v-btn>
    </p>
  </div>
</template>

<script>
import { authComputed } from '@state/helpers.js'

export default {
  name: 'DashboardWelcomeUser',
  computed: {
    ...authComputed,
  },
}
</script>

<style scoped></style>
