<template>
  <v-container fluid>
    <DashboardWelcomeUser />
    <DashboardToolsLinks class="mt-12"/>
  </v-container>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import DashboardWelcomeUser from "@components/dashboard/DashboardWelcomeUser.vue";
import DashboardToolsLinks from "@components/dashboard/DashboardToolsLinks.vue";

export default {
  page: {
    title: 'Dashboard',
  },
  components: {DashboardToolsLinks, DashboardWelcomeUser},
  computed: {
    ...authComputed,
  },
}
</script>
