<template>
  <v-row>
    <v-col v-for="link in getLinks()" :key="link.title" :cols="12" :sm="6" :md="6" :lg="4" :xl="4">
      <v-card
        :disabled="link.disabled"
        :color="link.color"
        dark
        :to="{ name: link.to }"
        class="fill-height"
        elevation="4"
        shaped
      >
        <v-card-text>
          <v-icon x-large style="font-size: 4rem">
            {{ link.icon }}
          </v-icon>
        </v-card-text>
        <v-card-title class="text-h5 font-weight-medium">
          {{ link.title }}
        </v-card-title>

        <v-card-subtitle class="text-overline">
          {{ link.description }}
        </v-card-subtitle>
        <v-card-actions>
          <v-btn text small>
            {{ link.action }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {authComputed} from "@state/helpers";

export default {
  name: 'DashboardToolsLinks',
  data() {
    return {
      riconciliazioneDisabled: false,
    }
  },
  computed: {
    ...authComputed,
  },
  beforeMount() {
    this.riconciliazioneDisabled = ['callcenter_manager', 'callcenter_operativi'].includes(this.userRole);
  },
  methods: {
    getLinks() {
      return [
        {
          title: 'Recupero Crediti',
          icon: 'mdi-cash-100',
          description: 'Gestisci le posizioni di recupero crediti.',
          to: 'tickets_search',
          action: 'Vai alla funzione',
          color: '#0064D3',
          dark: true,
          disabled: false,
        },
        {
          title: 'Tool Riconciliazione',
          description:
            'Accedi alla funzione di riconciliazione tra bollati e  movimenti bancari.',
          to: 'bollato_movimenti_create',
          action: 'Vai alla funzione',
          icon: 'mdi-bank-check',
          color: '#385F73',
          dark: true,
          disabled: this.riconciliazioneDisabled,
        },
        {
          title: 'Riconoscimento Targhe (Visure)',
          icon: 'mdi-car-back',
          description: 'Sezione in lavorazione.',
          to: 'bollato_movimenti_create',
          action: 'Vai alla funzione',
          color: '#0093d0',
          dark: true,
          disabled: true,
        },
      ]
    }
  }
}
</script>

<style scoped></style>
